import { tr } from 'mmfintech-commons'
import { useOnboarding } from '../../hooks'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { BannerWrapper, Centered, LargeButton, TextBody } from './OnboardingBanner.styled'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import WarningTriangleYellow from '../../images/icons/warning-yellow.svg?react'

export const OnboardingBanner = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus } = merchant || {}

  // const [_, { reset: resetPreview }] = useIbanRequestPreviewMutation({
  //   fixedCacheKey: 'iban-request-preview'
  // })

  // const [__, { reset: resetInitiate }] = useIbanRequestInitMutation({
  //   fixedCacheKey: 'iban-request-initiate'
  // })

  const { payAccountSetupFee, shouldPayAccountSetupFee, startOrContinueOnboarding } = useOnboarding()

  // const handleIbanRequestClick = () => {
  //   modalShow({
  //     options: {
  //       closeOnClickOutside: false,
  //       size: 'auto',
  //       onClose: () => {
  //         resetPreview()
  //         resetInitiate()
  //       }
  //     },
  //     content: <IbanRequestModal />
  //   })
  // }

  if (accountType === MerchantAccountTypeEnum.PROSPECT) {
    if (
      onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
      onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
      onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
    ) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('PORTAL.ONBOARDING.RESUME.TITLE', 'Complete your account')}
            </div>
            {tr(
              'PORTAL.ONBOARDING.RESUME.CONTENT',
              'Verify to get access to all features and increased payment limits.'
            )}
          </TextBody>
          <Centered className='ml-2'>
            {onboardingStatus === OnboardingStatusEnum.NOT_STARTED ? (
              <LargeButton
                data-test='start-onboarding'
                onClick={() => startOrContinueOnboarding()}
                text={tr('PORTAL.ONBOARDING.VERIFY_BUTTON', 'Verify')}
              />
            ) : (
              <Button
                data-test='start-onboarding'
                onClick={() => startOrContinueOnboarding()}
                text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              />
            )}
          </Centered>
        </BannerWrapper>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('PORTAL.ONBOARDING.ADDITIONAL_DOCUMENTS.TITLE', 'Complete your account')}
            </div>
            {tr(
              'PORTAL.ONBOARDING.ADDITIONAL_DOCUMENTS.CONTENT',
              'Complete the final step of your account activation - additional documents'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <Button
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              data-test='start-onboarding'
              onClick={() => startOrContinueOnboarding()}
            />
          </Centered>
        </BannerWrapper>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('PORTAL.ONBOARDING.QUESTIONNAIRE.TITLE', 'Questionnaire')}
            </div>
            {tr(
              'PORTAL.ONBOARDING.QUESTIONNAIRE.CONTENT',
              'Complete the questionnaire in order to proceed with the account onboarding'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <Button
              data-test='start-onboarding'
              text={tr('PORTAL.ONBOARDING.QUESTIONNAIRE.START_BUTTON', 'Start')}
              onClick={() => startOrContinueOnboarding()}
            />
          </Centered>
        </BannerWrapper>
      )
    }

    if (
      onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
      onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL
    ) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr('PORTAL.ONBOARDING.UNDER_REVIEW', 'Your account verification is under review.')}
            </div>
          </TextBody>
        </BannerWrapper>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr(
                'PORTAL.ONBOARDING.REJECTED',
                'Please contact Customer Service to get support on your account verification.'
              )}
            </div>
          </TextBody>
        </BannerWrapper>
      )
    }

    if (shouldPayAccountSetupFee()) {
      return (
        <BannerWrapper>
          <Centered className='mr-2'>
            <WarningTriangleYellow />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('PORTAL.ONBOARDING.SETUP_FEE.TITLE', 'Complete your account')}
            </div>
            {tr(
              'PORTAL.ONBOARDING.SETUP_FEE.CONTENT',
              'You need to pay your application fee to enable your primary account.'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <LargeButton
              data-test='setup-fee-button'
              onClick={payAccountSetupFee}
              text={tr('PORTAL.ONBOARDING.SETUP_FEE.PAY_BUTTON', 'Pay now')}
            />
          </Centered>
        </BannerWrapper>
      )
    }
  }

  // if (shouldRequestIban()) {
  //   return (
  //     <BannerWrapper>
  //       <Centered className='mr-2'>
  //         <WarningTriangleYellow />
  //       </Centered>
  //       <TextBody>
  //         <div className='onboarding-resume-header'>
  //           {tr('PORTAL.ONBOARDING.REQUEST_IBAN.TITLE', 'Complete your account')}
  //         </div>
  //         {tr('PORTAL.ONBOARDING.REQUEST_IBAN.CONTENT', 'You can get your own dedicated EUR IBAN.')}
  //       </TextBody>
  //       <Centered className='ml-2'>
  //         <LargeButton
  //           data-test='request-iban'
  //           onClick={handleIbanRequestClick}
  //           text={tr('PORTAL.ONBOARDING.REQUEST_IBAN.REQUEST_BUTTON', 'Request now')}
  //         />
  //       </Centered>
  //     </BannerWrapper>
  //   )
  // }

  return null
}
