import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  paths,
  useApplicationFeeInitiateMutation,
  useGetMerchantQuery,
  useMerchantAccounts,
  useOnboardingBannerQry
} from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, extractCurrencyCode, isValidString, isValidObject } from 'mmfintech-commons'

import { ApplicationFeeInfoModal } from './ApplicationFeeInfoModal'
import { ApplicationFeeDetailsModal } from './ApplicationFeeDetailsModal'
import { ApplicationFeeSuccessModal } from './ApplicationFeeSuccessModal'

import { AccountBalanceResponse, MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

export const useOnboarding = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { data: merchant } = useGetMerchantQuery()
  const { activeAccounts } = useMerchantAccounts()

  const [showError, setShowError] = useState(true)

  const history = useHistory()

  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const [initiatePayment /*, { error: initiateError, isLoading: initiateFetching }*/] =
    useApplicationFeeInitiateMutation()

  const startSumSubOnboarding = () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        history.push(paths.onboarding.selectType())
      } else {
        history.push(paths.onboarding.sumSub.start(entityType.toLowerCase()))
      }
    } else {
      history.push(paths.onboarding.selectType())
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: startSumSubOnboarding
  })

  const payAccountSetupFee = () => {
    modalHide()
    setShowError(true)

    const euroAccount: AccountBalanceResponse = activeAccounts.find(
      (account: any) => extractCurrencyCode(account) === 'EUR'
    )
    const { availableBalance } = euroAccount || {}
    const { amount, currency } = accountSetupFee || {}

    if (availableBalance >= amount) {
      const handleSubmit = async () => {
        setOtpOnSuccess(() => () => {
          modalShow({
            options: {
              size: 'auto',
              transparent: true,
              closeOnClickOutside: false,
              closeOnEscape: false
            },
            content: <ApplicationFeeSuccessModal />
          })
        })

        try {
          await initiatePayment({ accountId: euroAccount.id }).unwrap()
        } catch {}
      }

      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: (
          <ApplicationFeeInfoModal amount={amount} currency={currency} onClose={modalHide} onSubmit={handleSubmit} />
        )
      })
    } else {
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true
        },
        content: <ApplicationFeeDetailsModal amount={amount} currency={currency} />
      })
    }
  }

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible
  const requestIban = () => history.push(paths.ibanIssuing())

  return {
    showError,
    requestIban,
    shouldRequestIban,
    payAccountSetupFee,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}
