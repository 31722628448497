import styled from 'styled-components'

export const BadgeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  margin: 2.5rem 0 4.5rem 0;
`

export const FeeTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  width: 100%;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  .fee {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`

export const Fees = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1.8rem;
`

export const Buttons = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-flow: column-reverse;
  gap: 10px;
  button {
    width: 100%;
  }
  @media (min-width: 768px) {
    flex-flow: row;
  }
`

export const AccountsContainer = styled.div`
  margin: 3.5rem 0;
  .inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-height: 250px;
    overflow: auto;
    padding: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const IbanRequestModalWrapper = styled.div`
  width: 92rem;
  max-width: 100%;
  background: #ffffff;
  padding: 3rem 2rem;
  overflow: auto;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }
  .title {
    display: block;
    text-align: center;
    margin-bottom: 5rem;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
  }
  .instructions-title,
  .associate-iban-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .subtitle {
    font-size: 12px;
    line-height: 15px;
    color: #85a0ad;

    .fee-container {
      display: flex;
      justify-content: space-between;
    }

    a {
      color: #0d80f2;
      text-decoration: none;
    }
  }

  .fees-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;

    & > * {
      flex: 1;
    }
    @media (min-width: 940px) {
      flex-direction: row;
    }
  }
`
export const TabsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`
export const Tab = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  border-bottom: 3px solid rgba(163, 184, 194, 0.5);
  cursor: pointer;
  padding: 2rem;
  color: #85a0ad;
  width: 100%;
  text-align: center;
  &.active {
    color: #000000;
    border-bottom: 3px solid #000000;
  }
`
